import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { Vector as VectorSource } from 'ol/source';
import {
  VectorImage as VectorImageLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import OlFeature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import { TrackingService, VECTOR_LAYER } from './tracking.service';
import {
  TrackingService as OpenApiTrackingService,
  TrackingIncidentList,
  TrackingObdList,
} from '@openapi/tps';
import { ICONS_PATH } from './trackingStyles';
// import { normalEventIncidentDetail } from 'app/shared/fakeData/incidentCases';
import { ColorData } from '@fuse/others/constant';

import { TrackingSimulationService } from './tracking-simulation.service';
import {
  momentUnknownDateToIso,
  updateFeatureProperty,
  dateCompare,
  getCurrentHourDiff,
} from '@helpers';
import { containsCoordinate } from 'ol/extent';
import { INCIDENT_LAYER_INDEX } from '@constant/layers';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import getMedicalPriority from 'app/shared/helpers/priority';
import {
  DeviceHistoryEventType,
  junctionEventTypes,
  priorityOperationEventTypes,
} from '@constant/eventTypes';

import { PriorityValues } from '@constant/incidentCode';

export type IncidentFilter = {
  priorityFilter: PriorityValues[];
};

const DEFAULT_FILTER: IncidentFilter = {
  priorityFilter: ['P1+'],
};

export const INCIDENT_LAYER = 'INCIDENT';

@Injectable({
  providedIn: 'root',
})
export class IncidentTrackingService {
  constructor(
    private _trackingService: TrackingService,
    private _trackingSimulationService: TrackingSimulationService,
    private _openApiService: OpenApiTrackingService,
    private _fuseSidebarService: FuseSidebarService
  ) {
    this.incidentFilterSource = new BehaviorSubject(DEFAULT_FILTER);
    this.incidentFilter$ = this.incidentFilterSource.asObservable();
  }

  incidents = [];
  incidentData = [];
  incidentSource: VectorSource<any>;
  incidentLayer: VectorLayer<any>;
  shadowStyle: any;
  private incidentMarkersSource: BehaviorSubject<any> = new BehaviorSubject([]);
  incidentMarkers$: Observable<any> = this.incidentMarkersSource.asObservable();

  // Incident Filters
  private incidentFilterSource: BehaviorSubject<IncidentFilter>;
  public incidentFilter$: Observable<IncidentFilter>;
  priorityFilter: PriorityValues[] = ['P1+'];

  async initIncidentLayer() {
    // if (this.incidentLayer) return;
    console.log('INIT: Incidents Layer');
    this.clearIncidentLayer();

    const me = this;

    this.incidentSource = new VectorSource({
      features: me.incidents,
    });

    // custom shadow
    // this.shadowStyle = this._trackingService.getMarkerShadowStyle();

    this.incidentLayer = new VectorLayer({
      source: me.incidentSource,
      style: function (feature, resolution) {
        const isVisible = !!feature.get('visible');
        const isClosed = !!feature.get('dateClosed');
        if (isClosed) {
          return undefined;
        }
        if (isVisible) {
          const incidentStyle = new Style({
            image: new Icon({
              src: ICONS_PATH + feature.get('icon') + '.svg',
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              opacity: 1,
              scale: feature.get('scale'),
            }),
          });
          return [incidentStyle];
        }
        return undefined;
      },
      renderBuffer: 16,
      className: VECTOR_LAYER + '-' + INCIDENT_LAYER + '-markers',
    });

    this.incidentLayer.setZIndex(INCIDENT_LAYER_INDEX);
    this.incidentLayer.setVisible(true);

    this.listenIncidentObd();

    // fetch initial incident events
    // incidentEvents.forEach((incidentEvent: IncidentEvent) => {
    [].forEach((incidentEvent: any) => {
      const incidentMarker = this.createIncidentMarker(incidentEvent);
      this.incidents.push(incidentMarker);
      this.incidentData.push({
        data: incidentEvent,
        marker: incidentMarker,
      });
    });
    this.incidentMarkersSource.next(this.incidentData);

    try {
      const incidentObdList = await this._openApiService
        .trackingObdListGet()
        .toPromise();
      // console.log('obd-incidents get test', incidentObdList);
      incidentObdList.forEach(incident => this.processIncident(incident));
    } catch (e) {
      console.error('Failed to get obd-incidents', e);
    }

    try {
      const incidentList = await this._openApiService
        .trackingIncidentsListGet()
        .toPromise();

      // console.log('incidents get test', incidentList);
      incidentList.forEach(incident => this.processIncidents(incident));
    } catch (e) {
      console.error('Failed to get incidents', e);
    }

    this.listenFilter();
  }

  clearIncidentLayer() {
    this.incidentSource?.clear();
    if (this.incidents?.length > 0) {
      this.incidents = [];
      this.incidentData = [];
    }
  }

  createIncidentMarker(incidentEvent: any) {
    // console.log('incident marker creation event: ', incidentEvent);
    const { id, location, visible, ...eventData } = incidentEvent;
    const { latitude, longitude } = location;
    const iconType = 'incident';
    const featureName = VECTOR_LAYER + '-' + INCIDENT_LAYER + '-' + id;

    const properties = {
      iconType,
      ...eventData,
    };

    const incidentMarker = new OlFeature({
      type: 'icon',
      geometry: new Point(
        transform([longitude, latitude], 'EPSG:4326', 'EPSG:3857')
      ),
      name: featureName,
      description: id,
      color: ColorData.eBlack,
      scale: 0.85,
      longitude,
      latitude,
      visible,
      icon: iconType,
      ...properties,
    });
    return incidentMarker;
  }

  transformIncidentData(incident) {
    const {
      incidentNo,
      incidentLocation,
      incidentStation,
      dateCreated: dateCreatedUnformatted,
      incidentType,
      incidentPriority: dataIncidentPriority,
      dateClosed: dateClosedUnformatted,
      dateCallReceived: dateCallReceivedUnformatted,
      hospitalEta,
      vehicleNumber,
      callSign,
      callSignStatus,
      dateStatus: dateStatusUnformatted,
      dateArrivedAtScene: dateArrivedAtSceneUnformatted,
      dateArrivedatHospital: dateArrivedatHospitalUnformatted,
      dateDispatch: dateDispatchUnformatted,
      dateOperationEnd: dateOperationEndUnformatted,
      destination,
      junctionActivation: newJunctionActivation,
      priorityOperationMode,
      operationStatus,
      eventType,
      dateUpdated: dateUpdatedUnformatted,
    } = incident || {};
    const { latitude, longitude } = incidentLocation || {};

    // hardcode priority to P1+
    // const incidentPriority = 'P1+';
    const incidentPriority = getMedicalPriority(dataIncidentPriority);

    // transform to date strings
    const dateCreated = dateCreatedUnformatted
      ? momentUnknownDateToIso(dateCreatedUnformatted)
      : undefined;
    const dateCallReceived = dateCallReceivedUnformatted
      ? momentUnknownDateToIso(dateCallReceivedUnformatted)
      : undefined;
    const dateDispatch = dateDispatchUnformatted
      ? momentUnknownDateToIso(dateDispatchUnformatted)
      : undefined;
    const dateStatus = dateStatusUnformatted
      ? momentUnknownDateToIso(dateStatusUnformatted)
      : undefined;
    const dateArrivedAtScene = dateArrivedAtSceneUnformatted
      ? momentUnknownDateToIso(dateArrivedAtSceneUnformatted)
      : undefined;
    const dateArrivedatHospital = dateArrivedatHospitalUnformatted
      ? momentUnknownDateToIso(dateArrivedatHospitalUnformatted)
      : undefined;
    const dateOperationEnd = dateOperationEndUnformatted
      ? momentUnknownDateToIso(dateOperationEndUnformatted)
      : undefined;
    const dateClosed = dateClosedUnformatted
      ? momentUnknownDateToIso(dateClosedUnformatted)
      : undefined;
    const dateUpdated = dateUpdatedUnformatted
      ? momentUnknownDateToIso(dateUpdatedUnformatted)
      : undefined;

    const junctionActivations = [];
    let junctionActivation = null;
    if (junctionEventTypes.includes(eventType)) {
      junctionActivation = newJunctionActivation;
      junctionActivations.unshift(junctionActivation);
    }

    const operationStatusHistory = [];
    if (priorityOperationEventTypes.includes(eventType)) {
      operationStatusHistory.unshift({ dateUpdated, operationStatus });
    }

    const isVisible = this.isAllowedFilter(
      { priorityFilter: this.priorityFilter },
      { priority: incidentPriority }
    );

    const newIncidentData = {
      id: incidentNo,
      location: {
        latitude,
        longitude,
      },
      incidentStation,
      vehicleNumber,
      dateStatus,
      dateCreated,
      callSign,
      callSignStatus,
      dateArrivedAtScene,
      dateArrivedatHospital,
      dateDispatch,
      dateOperationEnd,
      destination,
      junctionActivation,
      junctionActivations,
      operationStatus,
      operationStatusHistory,
      priorityOperationMode,

      incidentType,
      priority: incidentPriority,
      fullPriority: dataIncidentPriority,
      dateClosed,
      dateCallReceived,
      hospitalEta,

      visible: isVisible,
    };
    return newIncidentData;
  }

  incidentSubscription: Subscription;
  incidentObdSubscription: Subscription;
  /**
   * handle incident and incident-obd updates from websocket
   */
  listenIncidentObd() {
    /**
     * Handle Incident Detail and Incident Close Events
     */
    this.incidentSubscription = this._trackingService.incidentUpdate$.subscribe(
      data => {
        this.processIncidents(data);
      }
    );

    /**
     * Handle Incident CallSign and OBD Events
     */
    this.incidentObdSubscription =
      this._trackingService.incidentObdUpdate$.subscribe(data => {
        this.processIncident(data);
      });
  }

  // incident OBD
  processIncident(data: TrackingObdList) {
    const {
      incidentNo,
      incidentLocation,
      vehicleNumber,
      callSign,
      callSignStatus,
      deviceId,
      dateStatus,
      incidentPriority: currentPriority,
      dateCreated,
      dateDispatch,
      destination,
      junctionActivation,
      operationStatus,
      dateClosed,
      eventType,
      dateUpdated,
      incidentStation,
    } = data;

    if (!incidentNo) return; // skip incident without incident number

    const { latitude, longitude } = incidentLocation || {};
    const currentIncident = this.findIncident(incidentNo, vehicleNumber);

    // new incident
    if (!currentIncident) {
      // console.log('new OBD incident: ', data);

      if (!currentPriority && dateClosed) {
        // handle CLOSED incident without P1+ - skip
        // console.log(
        //   'Incident without provided priority is closed. Skip: ',
        //   incidentNo
        // );
      } else {
        this.newIncident(data);
      }
    } else {
      // console.log('current OBD incident', currentIncident);

      const { marker, data: incidentData } = currentIncident;

      // hardcode priority to P1+
      // const incidentPriority = 'P1+';
      if (currentPriority) {
        const incidentPriority = getMedicalPriority(currentPriority);
        incidentData.priority = incidentPriority;
        marker.set('priority', incidentPriority);
        incidentData.fullPriority = currentPriority;
        marker.set('fullPriority', currentPriority);

        // update visibility
        const isVisible = this.isAllowedFilter(
          { priorityFilter: this.priorityFilter },
          { priority: incidentPriority }
        );
        marker.set('visible', isVisible);
      }

      if (deviceId) {
        incidentData.deviceId = deviceId;
      }

      if (callSign) {
        incidentData.callSign = callSign;
        incidentData.vehicleNumber = vehicleNumber;
        // marker.set('callSign', callSign);
        // marker.set('vehicleNumber', vehicleNumber);

        if (callSignStatus) {
          incidentData.callSignStatus = callSignStatus;
          // marker.set('callSignStatus', callSignStatus);
          // incidentData.dateStatus = moment()
          //   .utcOffset(environment.timezone)
          //   .toISOString();
        }

        if (dateStatus) {
          incidentData.dateStatus = momentUnknownDateToIso(dateStatus);
          // marker.set('dateStatus', incidentData.dateStatus);
        }

        if (destination) {
          incidentData.destination = destination;
          marker.set('destination', destination);
        }
      }

      if (dateCreated) {
        const dateCreatedFormatted = momentUnknownDateToIso(dateCreated);
        incidentData.dateCreated = dateCreatedFormatted;
        updateFeatureProperty(marker, 'dateCreated', incidentData.dateCreated);
      }

      if (dateDispatch) {
        const dateDispatchFormatted = momentUnknownDateToIso(dateDispatch);
        incidentData.dateDispatch = dateDispatchFormatted;
        marker.set('dateDispatch', incidentData.dateDispatch);
      }

      if (operationStatus) {
        if (operationStatus === 'auto' || operationStatus === 'manual') {
          incidentData.operationStatus = operationStatus;
        }
      }

      if (priorityOperationEventTypes.includes(eventType)) {
        // console.log(`Priority Session Update ${incidentNo}: `, operationStatus);
        if (!incidentData.operationStatusHistory) {
          incidentData.operationStatusHistory = [];
        }
        incidentData.operationStatusHistory.push({
          operationStatus,
          dateUpdated,
        });
        incidentData.operationStatusHistory =
          incidentData.operationStatusHistory.sort((a, b) =>
            this.compareJunctionDates(a, b)
          );
      }

      if (junctionEventTypes.includes(eventType)) {
        // console.log(
        //   `Junction ${incidentNo}: `,
        //   currentIncident,
        //   junctionActivation,
        //   incidentData.junctionActivations
        // );

        const { priorityAcceptance, sessionStatus } = junctionActivation;

        if (!incidentData.junctionActivations) {
          incidentData.junctionActivations = [];
        }
        // // remove extra activations?
        // else if (incidentData?.junctionActivations?.length >= 20) {
        //   incidentData?.junctionActivations?.pop();
        // }
        const junctionExists = incidentData?.junctionActivations?.find(
          junction => dateUpdated === junction.dateUpdated
        );
        if (junctionActivation && !junctionExists) {
          if (
            (sessionStatus === 'on' &&
              priorityAcceptance === 'granted' &&
              eventType === DeviceHistoryEventType.PRIORITY_SESSION) ||
            (priorityAcceptance === 'rejected' && sessionStatus === 'off')
          ) {
            junctionActivation.dateUpdated = dateUpdated;
            incidentData.junctionActivation = junctionActivation;
            incidentData.junctionActivations.unshift({
              ...junctionActivation,
              eventType,
            });

            incidentData.junctionActivations =
              incidentData.junctionActivations.sort((a, b) =>
                this.compareJunctionDates(a, b)
              );
          }
        }
      }

      // if (dateClosed) {
      //   incidentData.dateClosed = moment()
      //     .utcOffset(environment.timezone)
      //     .toISOString();
      // }

      // // mock data - reset info - remove for prod
      // if (type === 'new') {
      //   incidentData.junctionActivation = undefined;
      //   incidentData.priorityOperationMode = undefined;
      //   incidentData.dateDispatch = undefined;
      //   incidentData.hospital = undefined;
      //   incidentData.dateClosed = undefined;
      // }

      this.incidentMarkersSource.next(this.incidentData);
    }
  }

  compareJunctionDates(junctionA, junctionB) {
    const dateA = junctionA?.dateUpdated || null;
    const dateB = junctionB?.dateUpdated || null;
    if (!!dateA && !dateB) return -1;
    if (!dateA && !!dateB) return 1;
    if (!dateA && !dateB) return 0;
    return dateCompare(dateA, dateB);
  }

  processIncidents(data: TrackingIncidentList) {
    const { incidentNo } = data;
    if (!incidentNo) return; // skip incident without incident number
    // console.log('INCIDENT: data: ', incidentNo, data);
    const currentIncidents = this.findIncidents(incidentNo);

    if (!(currentIncidents?.length > 0)) {
      const { incidentPriority, dateClosed } = data || {};
      if (!incidentPriority && dateClosed) {
        // handle CLOSED incident without P1+ - skip
        // console.log('Incident without P1+ is closed - Skip.', incidentNo);
      } else {
        this.newIncident(data);
      }
    } else {
      // console.log(
      //   'INCIDENT: current incidents: ',
      //   incidentNo,
      //   currentIncidents,
      //   data
      // );
      currentIncidents.forEach(incident => {
        this.updateIncident(incident, data);
      });
      this.incidentMarkersSource.next(this.incidentData);
    }
  }

  newIncident(data) {
    // console.log('INCIDENT: new incident: ', data);
    const newIncidentData = this.transformIncidentData(data);

    // check if incident is old. skip if old
    const { dateCreated, id } = newIncidentData;

    const hourDiff = getCurrentHourDiff(dateCreated);

    if (hourDiff > 23) {
      // console.log(
      //   'Incident Tracking - Skip Old Incident: ',
      //   id,
      //   ' - ',
      //   hourDiff,
      //   ' hrs'
      // );
      return;
    }

    const incidentMarker = this.createIncidentMarker(newIncidentData);
    this.incidents.push(incidentMarker);
    this.incidentData.push({
      data: newIncidentData,
      marker: incidentMarker,
    });
    this.incidentSource.addFeature(incidentMarker);

    this.incidentMarkersSource.next(this.incidentData);
  }

  updateIncident(incident, data: TrackingIncidentList) {
    const { marker, data: incidentData } = incident;
    // console.log('INCIDENT: update incident: ', data, incidentData);
    const {
      incidentLocation,
      dateCreated,
      dateClosed,
      dateCallReceived,
      incidentStation,
      incidentType,
      hospitalEta,
      incidentPriority: priority,
    } = data;

    if (incidentLocation) {
      const { latitude, longitude } = incidentLocation;
      marker.setGeometry(
        new Point(
          transform(
            [Number(longitude), Number(latitude)],
            'EPSG:4326',
            'EPSG:3857'
          )
        )
      );
      updateFeatureProperty(marker, 'latitude', latitude);
      updateFeatureProperty(marker, 'longitude', longitude);
      incidentData.location = { ...incidentLocation };
    }

    if (dateCreated) {
      incidentData.dateCreated = momentUnknownDateToIso(dateCreated);
      updateFeatureProperty(marker, 'dateCreated', incidentData.dateCreated);
    }

    // hardcode priority to P1+
    // const incidentPriority = 'P1+';
    if (priority) {
      const incidentPriority = getMedicalPriority(priority);
      incidentData.priority = incidentPriority;
      marker.set('priority', incidentPriority);

      incidentData.fullPriority = priority;
      marker.set('fullPriority', priority);

      // update visibility
      const isVisible = this.isAllowedFilter(
        { priorityFilter: this.priorityFilter },
        { priority: incidentPriority }
      );
      marker.set('visible', isVisible);
    }

    if (dateClosed) {
      incidentData.dateClosed = momentUnknownDateToIso(dateClosed);
      marker.set('dateClosed', incidentData.dateClosed);
    }

    if (dateCallReceived) {
      incidentData.dateCallReceived = momentUnknownDateToIso(dateCallReceived);
      marker.set('dateCallReceived', incidentData.dateCallReceived);
    }

    if (incidentStation) {
      incidentData.incidentStation = incidentStation;
    }

    if (incidentType) {
      incidentData.incidentType = incidentType;
    }

    if (hospitalEta) {
      incidentData.hospitalEta = hospitalEta;
    }
  }

  isVisible() {
    const isLayerVisible = this.incidentLayer?.getVisible() ?? true;
    return isLayerVisible;
  }

  toggleVisibility(value) {
    this.incidentLayer.setVisible(value);
  }

  findIncident(id: string, vehicleNumber?: string) {
    const currentIncident = this.incidentData?.find(incident => {
      const { data } = incident;
      const { id: incidentId, vehicleNumber: incidentVehicleNumber } =
        data || {};
      if (incidentId === id) {
        if (incidentVehicleNumber && vehicleNumber) {
          return incidentVehicleNumber === vehicleNumber;
        } else {
          return true;
        }
      }
    });
    return currentIncident;
  }

  findIncidents(id: string, vehicleNumber?: string) {
    const currentIncidents =
      this.incidentData?.filter(incident => {
        const { data } = incident;
        const { id: incidentId, vehicleNumber: incidentVehicleNumber } =
          data || {};
        if (incidentId === id) {
          if (incidentVehicleNumber && vehicleNumber) {
            return incidentVehicleNumber === vehicleNumber;
          } else {
            return true;
          }
        }
      }) ?? [];
    return currentIncidents;
  }

  findIncidentFeature(vehicleNumber: string) {
    const features = this.incidentLayer.getSource().getFeatures();

    const currFeature =
      features.find(feature => {
        const featureName = feature.get('description');
        // console.log('feature', feature, featureName, vehicleNumber);
        return featureName === vehicleNumber;
      }) ?? null;

    return currFeature;
  }

  panToIncident(id: string) {
    const incidentFeature = this.findIncidentFeature(id);
    // console.log('incident feature click', incidentFeature);
    if (incidentFeature) {
      const incidentCoords = incidentFeature.getGeometry().getCoordinates();
      const currExtent = this._trackingService.mapBoundingExtent;
      if (containsCoordinate(currExtent, incidentCoords)) {
        const mapView = this._trackingService.getMapView;
        const point = new Point(incidentCoords);
        const isIncidentListPanelOpen =
          this._fuseSidebarService.getSidebar('trackingPanel')?.opened ?? false;
        const padding = isIncidentListPanelOpen ? 700 : 0;
        mapView.fit(point, {
          padding: [0, 0, 0, padding], // sidebar panel width
          maxZoom: 18,
          duration: 2000,
        });
      } else {
        console.log(
          'Error: Point is not within map boundaries',
          incidentCoords
        );
      }
    }
  }

  getIncidentDetail(incidentNo: string) {
    // TODO: use openAPI later
    // console.log('get detail', incidentNo);
    // mock api response
    // const simData =
    //   this._trackingSimulationService.getIncidentDetails(incidentNo);

    // if (simData) {
    //   return of(simData);
    // }

    // if (incidentNo === normalEventIncidentDetail.incidentNo) {
    //   // console.log('', normalEventIncidentDetail);
    //   return of(normalEventIncidentDetail);
    // }

    //openapi
    try {
      const detailDataObs = this._openApiService.trackingIncidentsDetailPost({
        incidentNo,
      });
      return detailDataObs;
    } catch (e) {
      console.error('Error fetching incident detail data', e);
      return of({});
    }

    // mock observable
    return of({});
  }

  updatePriorityFilter(filter: PriorityValues[]) {
    this.priorityFilter = filter;
    this.incidentLayer.changed();
    this.updateIncidentFilter();
  }

  updateIncidentFilter() {
    this.incidentFilterSource.next({
      priorityFilter: this.priorityFilter,
    });
  }

  isAllowedFilter(filter: IncidentFilter, data: { priority: PriorityValues }) {
    const { priorityFilter } = filter;
    const { priority } = data;
    // console.log('priority check: ', priorityFilter, priority);
    if (priorityFilter?.length > 0) {
      const isPriorityIncluded = priorityFilter.includes(priority);
      if (!isPriorityIncluded) return false;
    }
    return true;
  }

  // monitor for priority change
  listenFilter() {
    this.incidentFilter$.subscribe(newFilter => {
      const { priorityFilter } = newFilter;

      this.incidentLayer.getSource().forEachFeature(feature => {
        const featurePriority = feature.get('priority');
        const isVisible = this.isAllowedFilter(
          { priorityFilter },
          { priority: featurePriority }
        );
        feature.set('visible', isVisible);
      });
    });
  }
}
